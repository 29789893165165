import React from 'react'
import { MOBILE_WIDTH } from '../../lib/utils/styles'
import useMedia from '../../lib/hooks/media'

interface BreakpointStyles {
  top: string
  left?: string
  right?: string
  translateY: string
  fontSize: string
}

interface FluidValueProps {
  text: string
  styles: Record<string, string>
  mobileStyles: BreakpointStyles
  desktopStyles: BreakpointStyles
}

export default function FluidText({ text, styles, mobileStyles, desktopStyles }: FluidValueProps): React.ReactElement {
  const isMobile = useMedia(`@media screen and (max-width: ${MOBILE_WIDTH}px)`)
  const textStyles = isMobile ? mobileStyles : desktopStyles

  return (
    <span
      className={styles.text}
      style={{
        ...styles,
        top: textStyles.top,
        left: textStyles.left,
        right: textStyles.right,
        transform: textStyles.translateY,
        fontSize: textStyles.fontSize,
      }}
    >
      {text}
    </span>
  )
}
