import React from 'react'

import Breadcrumb from './Breadcrumb'
import { StaticBackground } from '../Background/StaticBackground'
import styles from './Header.module.scss'

interface HeaderProps {
  currentPage: string
}

export default function Header({ currentPage, children }: React.PropsWithChildren<HeaderProps>): React.ReactElement {
  return (
    <header className={styles.container}>
      <StaticBackground />

      <div className={styles.header}>
        <Breadcrumb currentPage={currentPage}></Breadcrumb>
        <h1 className={styles.heading}>{children}</h1>
      </div>
    </header>
  )
}
