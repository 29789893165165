import axios from 'axios'
import { camelizeKeys } from 'humps'

export const UserInputJediTypes = [4, 25] // Jedi types that are user input components, i.e. text fields

export interface StartSessionResponse {
  code?: number
  success?: boolean
  sessionToken?: string
  errorMessage?: string
}

export interface Component {
  kind: number
  name: string
  items?: Item[]
  parameters: Record<any, any>
}

export interface Item {
  type: number
  label: string
  text: string
}

export interface Panel {
  panelToken?: string
  sessionToken?: string
  components?: Component[]
  displaySearchBar?: boolean
}

export interface GetPanelResponse {
  code?: number
  panel?: Panel
  success?: boolean
  transitionId?: string
  errorMessage?: string
}

export interface Input {
  kind?: string
  name?: string
  value?: string
}

export interface Option {
  label: string
  text: string
}

export enum InputKind {
  BUTTON_PRESS,
  TEXT,
  BOOLEAN,
}

interface GetPanelRequest {
  inputs: Input[]
  sessionToken: string
  panelToken?: string
}

export const startSession = async (country: string, language: string): Promise<StartSessionResponse> => {
  const { data } = await axios({
    method: 'post',
    url: '/api/v1/jedi/session/start',
    data: {
      client: 'block-help',
      country: country,
      // eslint-disable-next-line camelcase
      language_code: language,
    },
  })

  return camelizeKeys(data) as StartSessionResponse
}

export const getPanel = async ({ inputs, sessionToken, panelToken }: GetPanelRequest): Promise<GetPanelResponse> => {
  const { data } = await axios({
    method: 'post',
    url: '/api/v1/jedi/session/get_panel',
    data: {
      inputs: inputs,
      // eslint-disable-next-line camelcase
      panel_token: panelToken,
      // eslint-disable-next-line camelcase
      session_token: sessionToken,
    },
  })

  return camelizeKeys(data) as GetPanelResponse
}
