import React from 'react'
import styles from './Paragraph.module.scss'

interface ParagraphProps {
  parameters: {
    emphasis: string
    isHTML: string
    text: string
  }
}

export default function Paragraph({ parameters }: ParagraphProps) {
  const { text } = parameters
  return <p className={styles.paragraph} dangerouslySetInnerHTML={{ __html: text }} />
}
