import React from 'react'
import styles from './Message.module.scss'

interface MessageProps {
  parameters: {
    text: string
    type: string
  }
}

const Message = ({ parameters }: MessageProps): React.ReactElement => {
  const { text, type } = parameters
  return (
    <div className={`${styles.message} ${type === 'error' ? styles.error : styles.success}`} data-cy="message">
      {text}
    </div>
  )
}

export default Message
