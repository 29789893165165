import React from 'react'
import styles from './Spinner.module.scss'

interface SpinnerProps {
  size?: string
}

const Spinner = ({ size = 'base' }: SpinnerProps): React.ReactElement => {
  return (
    <div className={`${styles.loader} ${size === 'base' ? styles.base : styles.sm}`}>
      <svg className={styles.circularLoader} viewBox="25 25 50 50" data-cy="loading-spinner">
        <circle
          className={styles.loaderPath}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </div>
  )
}

export default Spinner
