import React, { useState } from 'react'
import * as yup from 'yup'

import { Component, UserInputJediTypes } from '../../clients/jedi'
import { Paragraph } from '../Paragraph'
import Select from '../Select/Select'
import { TextInput } from '../TextInput'
import { Submit } from '../Submit'
import styles from './Panel.module.scss'

const componentByJediType: Record<number, React.JSXElementConstructor<any>> = {
  4: TextInput,
  8: Submit,
  19: Paragraph,
  25: Select,
}

export type FormState = Record<string, any>

export interface FormContext {
  isSubmitting: boolean
  error?: yup.ValidationError
}
interface PanelProps {
  components: Component[]
  onFormSubmit: (formValues: FormState) => void
  context: {
    isSubmitting: boolean
    validationErrors: Record<string, yup.ValidationError>
  }
}

const getInitialFormStateFromComponents = (components: Component[]): FormState => {
  return components.reduce((state, component) => {
    if (UserInputJediTypes.includes(component.kind)) {
      state[component.name] = ''
    }
    return state
  }, {} as FormState)
}

const Panel = ({ components, onFormSubmit, context }: PanelProps): React.ReactElement => {
  const [formInput, setFormInput] = useState<FormState>(getInitialFormStateFromComponents(components))

  const handleFormChange = (fieldName: string, newValue: string) => {
    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      [fieldName]: newValue,
    }))
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    onFormSubmit(formInput)
  }

  return (
    <form onSubmit={onSubmit}>
      {components.map((component, i) => {
        const JediComponent = componentByJediType[component.kind]

        if (!component.kind || !JediComponent) {
          return null
        }

        return (
          <div className={styles.componentWrapper} key={i} data-cy={component.name}>
            <JediComponent
              parameters={component.parameters}
              value={formInput[component.name]}
              onChange={(newValue: string) => handleFormChange(component.name, newValue)}
              context={{ error: context.validationErrors[component.name], isSubmitting: context.isSubmitting }}
              items={component.items}
              name={component.name}
            />
          </div>
        )
      })}
    </form>
  )
}

export default Panel
