import * as React from 'react'
interface CaretIconProps {
  className?: string
}
const CaretIcon = (props: React.PropsWithChildren<CaretIconProps>) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.59 0.59L6 5.17L1.41 0.59L0 2L6 8L12 2L10.59 0.59Z" fill="currentColor" className={props.className} />
    </svg>
  )
}

export default CaretIcon
