import React from 'react'

import styles from './Header.module.scss'

interface BreadcrumbProps {
  currentPage: string
}

export default function Breadcrumb({ currentPage }: BreadcrumbProps): React.ReactElement {
  return (
    <div className={styles.breadcrumb}>
      <a href="https://block.xyz/">Block</a>
      <span> / </span>
      {currentPage}
    </div>
  )
}
