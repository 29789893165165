import React from 'react'
import styles from './Layout.module.scss'

export default function Layout({ children }: React.PropsWithChildren<{}>): React.ReactElement {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.row}>{children}</div>
        </div>
      </div>
    </div>
  )
}
