import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import CaretIcon from '../Icons/CaretIcon'
import styles from './Select.module.scss'
import { Option } from '../../clients/jedi'
import { FormContext } from '../Panel/Panel'
import { showFormFieldError } from '../../lib/utils/validation'
import useOnClickOutside from '../../lib/hooks/useOnClickOutside'

interface SelectProps {
  name: string
  items: Option[]
  parameters: {
    name: string
    required: boolean
    label: string
    placeholder: string
    errorState: string
    text?: string
  }
  value: string
  context: FormContext
  onChange: (newValue: string) => void
}

const Select = ({ parameters, items, context, value, onChange, name }: SelectProps) => {
  const { label, placeholder, errorState, text } = parameters
  const { error } = context

  const showError: boolean = showFormFieldError(error, value, errorState, text)

  const [active, setActive] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const optionsRef = useRef<Record<string, HTMLDivElement | null>>({})
  const selectButtonRef = useRef<HTMLButtonElement>(null)

  const toggleDropdown = () => setActive(!active)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!active) {
      return
    }
    const optionToFocus = selectedItem ? optionsRef.current[selectedItem] : optionsRef.current[items[0].label]
    optionToFocus?.focus()
  }, [active])

  const selectedItemText = useMemo(() => {
    if (!selectedItem) {
      return null
    }

    return items.find((item) => item.label === selectedItem)?.text
  }, [selectedItem])

  const handleItemClick = (label: string) => {
    if (selectedItem !== label) {
      onChange(label)
      setSelectedItem(label)
    }
    setActive(!active)
  }

  const onToggleKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Tab' && e.shiftKey && active) {
      setActive(!active)
    }

    if (e.code === 'Escape') {
      selectButtonRef?.current?.focus()
      setActive(!active)
    }
  }

  useOnClickOutside(containerRef, () => setActive(false))

  const onOptionKeyDown = (event: React.KeyboardEvent, label: string) => {
    switch (event.code) {
      case 'Tab':
        if (!event.shiftKey && !document.activeElement?.nextSibling) {
          event.preventDefault()
        }
        break

      case 'ArrowUp':
        event.preventDefault()
        if (document.activeElement?.previousSibling) {
          const previousSibling = document.activeElement?.previousSibling as HTMLLIElement
          previousSibling.focus()
        }
        break

      case 'ArrowDown':
        event.preventDefault()
        if (document.activeElement?.nextSibling) {
          const nextSibling = document.activeElement?.nextSibling as HTMLLIElement
          nextSibling.focus()
        }
        break

      case 'Enter':
        onChange(label)
        setSelectedItem(label)
        setActive(!active)
        break

      case 'Escape':
        selectButtonRef?.current?.focus()
        setActive(!active)
        break

      default:
        return
    }
  }

  return (
    <>
      <div className={styles.inputGroup} ref={containerRef}>
        <label className={styles.selectlabel} id={`select_${name}`}>
          {label}
        </label>

        <div className={classNames(styles.selectContainer, { [styles.inputError]: showError })}>
          <button
            type="button"
            role="combobox"
            ref={selectButtonRef}
            className={classNames(styles.selectToggle, { [styles.active]: active })}
            onClick={toggleDropdown}
            onKeyDown={onToggleKeyDown}
            tabIndex={0}
            aria-haspopup="listbox"
            aria-controls={`listbox_${name}`}
            aria-expanded={active}
            aria-labelledby={`select_${name}`}
            aria-activedescendant={selectedItem ?? ''}
          >
            {selectedItemText ?? placeholder}
            <CaretIcon className={styles.icon}></CaretIcon>
          </button>

          <div
            tabIndex={-1}
            role="listbox"
            id={`listbox_${name}`}
            className={classNames(styles.selectListbox, { [styles.active]: active })}
          >
            {items.map((option) => (
              <div
                key={option.label}
                className={styles.listBoxItem}
                onMouseUp={() => handleItemClick(option.label)}
                onKeyDown={(e) => onOptionKeyDown(e, option.label)}
                ref={(node) => (optionsRef.current[option.label] = node)}
                id={option.label}
                role="option"
                tabIndex={0}
                aria-labelledby={option.label}
                aria-selected={option.label === selectedItem}
              >
                <div className={styles.listBoxItemInner}>{option.text}</div>
                <hr aria-orientation="horizontal" className={styles.divider}></hr>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p className={styles.inputErrorMessage}>{showError && error?.message}</p>
    </>
  )
}

export default Select
