import React from 'react'
import Arrow from '../Icons/Arrow'
import styles from './Submit.module.scss'
import Spinner from './../Spinner'
import { FormContext } from '../Panel/Panel'

interface SubmitProps {
  parameters: {
    label: string
    validate: string
  }
  context: FormContext
}

export default function Submit({ parameters, context }: SubmitProps): React.ReactElement {
  const { label } = parameters
  const { isSubmitting } = context

  return (
    <button type="submit" className={styles.submit} disabled={isSubmitting}>
      {isSubmitting ? 'Loading...' : label}
      {isSubmitting ? <Spinner size="sm" /> : <Arrow />}
    </button>
  )
}
