import React, { useEffect, useRef } from 'react'

export default function LogoVideo() {
  const video = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches == false) {
      video?.current?.play()
    }
  }, [])

  return (
    <video
      title={'Block brand asset'}
      ref={video}
      controls={false}
      autoPlay={false}
      playsInline
      muted
      loop
      poster={'logo/png/block_400.png'}
    >
      <source src={'logo/png/block_400.png'} media="(prefers-reduced-motion: reduce)" type="image/png" />
      <source src={'logo/hevc/block_400.mov'} type='video/quicktime; codecs="hvc1"' />
      <source src={'logo/webm/block_400.webm'} type="video/webm" />
    </video>
  )
}
