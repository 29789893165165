import React from 'react'
import { useBackground } from '@block/kettlebell-react'

import styles from './StaticBackground.module.scss'

export const StaticBackground = () => {
  const { isWebGLAvailable } = useBackground()

  if (isWebGLAvailable) {
    return null
  }

  return <div className={styles.staticBackground} data-testid="static-background" />
}
