import { useMemo, useState } from 'react'
import * as yup from 'yup'
import { Component, UserInputJediTypes } from '../../clients/jedi'
import { FormState } from '../../components/Panel/Panel'

export const useValidationSchema = (components: Component[]) => {
  const [validationErrors, setValidationErrors] = useState<Record<string, yup.ValidationError>>({})

  const schema: Record<string, any> = useMemo(() => {
    return components.reduce((schema, component) => {
      if (UserInputJediTypes.includes(component.kind)) {
        schema[component.name] = getValidationtByJediType(component)
      }
      return schema
    }, {} as Record<string, any>)
  }, [components])

  const validateForm = async (formInput: FormState): Promise<boolean> => {
    try {
      await yup.object(schema).validate(formInput, { abortEarly: false, stripUnknown: true })
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const errors = err.inner.reduce((errors: Record<string, yup.ValidationError>, current: yup.ValidationError) => {
          if (current.path) {
            errors[current.path] = current
          }
          return errors
        }, {})
        setValidationErrors(errors)
        return false
      }
    }
    return true
  }

  return {
    validateForm: async (formInput: FormState) => validateForm(formInput),
    validationErrors: validationErrors,
  }
}

const getValidationtByJediType = (component: Component) => {
  const { name, parameters, kind, items } = component
  const { label, minLength, required } = parameters
  let schema
  switch (kind) {
    case 4: // TEXT_FIELD
      schema = yup.string()
      if (minLength) {
        schema = schema.min(parseInt(minLength))
      }
      if (name === 'email') {
        schema = schema.email('Email must be a valid email')
      }
      if (required === 'true') {
        schema = schema.required(`${label} is required`)
      }
      return schema
    case 25: {
      // SINGLE_SELECT_LIST
      schema = yup.mixed()
      const validItems = (items ?? []).map((item) => item.label)
      let message = ''

      if (required === 'true') {
        message = `Selecting a ${label} is required`
      } else {
        validItems.push('')
      }
      return schema.oneOf(validItems, message)
    }
    default:
      return
  }
}
