import classNames from 'classnames'
import React from 'react'
import { FormContext } from '../Panel/Panel'
import { showFormFieldError } from '../../lib/utils/validation'
import styles from './TextInput.module.scss'

interface TextAreaProps {
  parameters: {
    text?: string // text that was originally submitted when API gave error response
    required: string
    minLength: string
    errorState: string
    label: string
    multiline: string
    placeholder: string
    type: string
  }
  value: string
  onChange: (newValue: string) => void
  context: FormContext
}

export default function TextArea({ parameters, value, onChange, context }: TextAreaProps): React.ReactElement {
  const { text, errorState, label, multiline, placeholder, type } = parameters
  const { error } = context

  const showError: boolean = showFormFieldError(error, value, errorState, text)

  const renderInput = () => {
    if (multiline === 'true') {
      return (
        <div className={classNames(styles.textAreaOuter, { [styles.inputError]: showError })}>
          <textarea
            className={styles.textArea}
            name={label}
            placeholder={placeholder ?? label}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            data-testid="textarea"
          />
        </div>
      )
    }
    return (
      <input
        className={classNames(styles.input, { [styles.inputError]: showError })}
        type={type}
        name={label}
        placeholder={placeholder ?? label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        data-testid="textinput"
      />
    )
  }

  return (
    <>
      <span className={styles.inputGroup}>
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
        {renderInput()}
      </span>
      <p className={styles.inputErrorMessage} data-testid="error-msg">
        {showError && error?.message}
      </p>
    </>
  )
}
