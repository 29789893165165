import geoip from 'geoip-lite'
import requestIp from 'request-ip'
import parser from 'accept-language-parser'
import { camelizeKeys } from 'humps'
import * as Sentry from '@sentry/nextjs'
import { useEffect, useRef, useState } from 'react'
import { GetServerSideProps } from 'next'
import {
  startSession,
  getPanel,
  StartSessionResponse,
  GetPanelResponse,
  Input,
  Panel as JediPanel,
  InputKind,
} from '../clients/jedi'
import { useValidationSchema } from '../lib/hooks/useValidationSchema'

import Header from '../components/Header'
import Footer from '../components/Footer'
import { Layout } from '../components/Layout'
import { Message } from '../components/Message'
import Panel from '../components/Panel'
import Spinner from '../components/Spinner'
import { FormState } from '../components/Panel/Panel'
import { messages } from '../util/messages'
import styles from '../components/Panel/Panel.module.scss'
import FluidText from '../components/FluidText'
import { fluidMobileValue, fluidDesktopValue } from '../lib/utils/styles'

const SUPPORTED_LANGUAGES = [
  //https://shuttle.squareup.com/search/translations?query=Civil+Process+Balance+Update+Inquiry&project_id=86&field=searchable_source_copy&commit=Search
  // accept-language header codes are in BCP 47,
  // which start with a a 2-letter primary language subtag in ISO 639-1
  // Jedi accepts language codes in ISO 639-1, so we will ignore codes with subtags such as 'fr-CA',
  // and only consider the starting 2-letter code
  'ca',
  'en',
  'es',
  'fr',
  'ja',
]

interface ContactProps {
  country: string
  language: string
  panelToken: string
}

export default function Contact({ country, language, panelToken }: ContactProps) {
  const [sessionToken, setSessionToken] = useState('')
  const [panel, setPanel] = useState<JediPanel | null>(null)
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState({ type: '', text: '' })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isError = !panel && !loading

  const contentRef = useRef<HTMLDivElement>(null)

  const { validateForm, validationErrors } = useValidationSchema(panel?.components ?? [])

  const getInputKind = (kind: number) => {
    switch (kind) {
      case 8: // SUBMIT
        return InputKind[InputKind.BUTTON_PRESS]
      default:
        return InputKind[InputKind.TEXT]
    }
  }

  const formatUserInputs = (formInputs: FormState): Input[] => {
    const components = panel?.components ?? []

    return components.reduce((inputs, component) => {
      if (component.name && component.kind) {
        inputs.push({
          kind: getInputKind(component.kind),
          name: component.name,
          value: component.name !== 'submit' ? formInputs[component.name] : 'true',
        })
      }
      return inputs
    }, [] as Input[])
  }

  const onFormSubmit = async (formInput: FormState) => {
    const valid = await validateForm(formInput)
    if (!valid) {
      return
    }

    setIsSubmitting(true)
    setLoading(true)
    getPanel({ inputs: formatUserInputs(formInput), sessionToken })
      .then((response) => {
        if (response.success) {
          setMessage({ type: 'success', text: messages.onSuccess })
        } else {
          setMessage({ type: 'error', text: messages.onError })
        }
      })
      .catch((error) => {
        Sentry.captureException(error)
        setMessage({ type: 'error', text: messages.onError })
      })
      .finally(() => {
        contentRef?.current?.scrollIntoView()
        setLoading(false)
        setIsSubmitting(false)
        setPanel(null)
      })
  }

  useEffect(() => {
    setLoading(true)

    startSession(country, language)
      .then((data) => {
        const { success, sessionToken } = camelizeKeys(data) as StartSessionResponse

        if (success && sessionToken) {
          setSessionToken(sessionToken)
        } else {
          setMessage({ type: 'error', text: messages.onLoadingError })
        }
      })
      .catch((error) => {
        Sentry.captureException(error)
        setMessage({ type: 'error', text: messages.onLoadingError })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (!sessionToken) {
      return
    }

    setLoading(true)

    getPanel({ inputs: [], sessionToken, panelToken })
      .then((data) => {
        const { success, panel } = camelizeKeys(data) as GetPanelResponse
        if (success && panel) {
          setPanel(panel)
        } else {
          setMessage({ type: 'error', text: messages.onLoadingError })
        }
      })
      .catch((error) => {
        Sentry.captureException(error)
        setMessage({ type: 'error', text: messages.onLoadingError })
      })
      .finally(() => setLoading(false))
  }, [panelToken, sessionToken])

  return (
    <>
      <Header currentPage={'Contact'}>
        <FluidText
          text={'Contact'}
          styles={{ lineHeight: '160%', position: 'absolute' }}
          mobileStyles={{
            top: '50%',
            left: fluidMobileValue(42, 180),
            translateY: 'translateY(-110%)',
            fontSize: fluidMobileValue(50, 80),
          }}
          desktopStyles={{
            top: fluidDesktopValue(116, 83),
            left: fluidDesktopValue(250, 335),
            translateY: 'none',
            fontSize: fluidDesktopValue(100, 150),
          }}
        />
        <FluidText
          text={'Civil'}
          styles={{ lineHeight: '160%', position: 'absolute' }}
          mobileStyles={{
            top: '50%',
            right: fluidMobileValue(132, 235),
            translateY: 'translateY(-40%)',
            fontSize: fluidMobileValue(50, 80),
          }}
          desktopStyles={{
            top: fluidDesktopValue(255, 221),
            right: fluidDesktopValue(220, 334),
            translateY: 'none',
            fontSize: fluidDesktopValue(100, 150),
          }}
        />
        <FluidText
          text={'Process'}
          styles={{ lineHeight: '160%', position: 'absolute' }}
          mobileStyles={{
            top: '50%',
            left: fluidMobileValue(68, 220),
            translateY: 'translateY(20%)',
            fontSize: fluidMobileValue(50, 80),
          }}
          desktopStyles={{
            top: fluidDesktopValue(380, 359),
            left: fluidDesktopValue(190, 491),
            translateY: 'none',
            fontSize: fluidDesktopValue(100, 150),
          }}
        />
      </Header>
      <Layout>
        {loading && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
        {isError && <Message parameters={message} />}
        {panel && (
          <Panel
            components={panel?.components ?? []}
            onFormSubmit={onFormSubmit}
            context={{ isSubmitting, validationErrors }}
          />
        )}
      </Layout>
      <Footer />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  let country = 'US'
  let language = 'en'

  const ip = requestIp.getClientIp(req)
  const acceptLanguage = req.headers['accept-language']
  if (ip && acceptLanguage) {
    country = geoip.lookup(ip)?.country ?? country
    language = parser.pick(SUPPORTED_LANGUAGES, acceptLanguage) ?? language
  }

  return {
    props: {
      country,
      language,
      panelToken: process.env.JEDI_PANEL_ID,
    },
  }
}
