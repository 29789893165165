import { useEffect, useState } from 'react'

export default function useMedia(query: string): boolean {
  query = query.replace('@media ', '')
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const media = window.matchMedia(query)
      setMatches(media.matches)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [query])

  return matches
}
