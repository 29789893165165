import React, { useMemo } from 'react'
import LogoVideo from './LogoVideo'
import { StaticBackground } from '../Background/StaticBackground'
import styles from './Footer.module.scss'

export default function Footer() {
  const currentYear = useMemo(() => {
    return new Date().getFullYear().toString()
  }, [])

  return (
    <footer className={styles.footer}>
      <StaticBackground />

      <div className={styles.wrapper}>
        <div className={styles.container}>
          <a className={styles.videoLink} href="https://block.xyz/" aria-label="Link to homepage">
            <LogoVideo />
          </a>
          <div className={styles.buildingBlocksGroup}>
            <h4 className={styles.linksTitle}>Building blocks</h4>
            <ul className={styles.linksList}>
              <li>
                <a href="https://squareup.com/" target="_blank" rel="noopener noreferrer">
                  Square
                </a>
              </li>
              <li>
                <a href="https://cash.app/" target="_blank" rel="noopener noreferrer">
                  Cash App
                </a>
              </li>
              <li>
                <a href="https://spiral.xyz/" target="_blank" rel="noopener noreferrer">
                  Spiral
                </a>
              </li>
              <li>
                <a href="https://tidal.com/" target="_blank" rel="noopener noreferrer">
                  TIDAL
                </a>
              </li>
              <li>
                <a href="https://twitter.com/TBD54566975" target="_blank" rel="noopener noreferrer">
                  TBD
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.followUsGroup}>
            <h4 className={styles.linksTitle}>Follow us</h4>
            <ul className={styles.linksList}>
              <li>
                <a href="https://twitter.com/blocks" target="_blank" rel="noopener noreferrer">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/joinblock" target="_blank" rel="noopener noreferrer">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/block.xyz" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>
              </li>
            </ul>
            <span
              className={styles.copyright}
            >{`© ${currentYear} Block, Inc. BLOCK and the Block Logo are trademarks of Block, Inc.`}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}
