export const XS_WIDTH = 360 // px
export const MOBILE_WIDTH = 960 // px
export const CONTAINER_WIDTH = 1680 // px

/**
 * In CSS calc() multiplication and division, if one term is a unit based value,
 * all other terms cannot be unit based values. Because of this, we must remove
 * 'px' from all terms besides (100vw - ${minViewportWidth}).
 */
const fluidValue = (minSize: number, maxSize: number, minViewportWidth: number, maxViewportWidth: number): string => {
  return `calc(${minSize}px + (${maxSize} - ${minSize}) *  min(1px, ((100vw - ${minViewportWidth}px) / (${maxViewportWidth} - ${minViewportWidth}))))`
}

export function fluidDesktopValue(min: number, max: number): string {
  return fluidValue(min, max, MOBILE_WIDTH, CONTAINER_WIDTH)
}

export function fluidMobileValue(min: number, max: number): string {
  return fluidValue(min, max, XS_WIDTH, MOBILE_WIDTH)
}
